/* eslint-disable no-useless-escape */
import {
  ref, readonly, computed, useContext,
} from '@nuxtjs/composition-api';
import type { Ref } from '@nuxtjs/composition-api';
import mask from '~/composables/utils/mask';
import { Logger } from '~/helpers/logger';
import { useCustomerStore } from '~/modules/customer/stores/customer';
import { useCart } from '~/modules/checkout/composables/useCart';
import { generateUserData } from '~/modules/customer/helpers/generateUserData';
import type { Customer } from '~/modules/GraphQL/types';

import axios from 'axios';

import { useApi } from '~/composables/useApi';
import type {
  UseUserInterface,
  UseUserErrors,
  UseUserLoadParams,
  UseUserLoginParams,
  UseUserLogoutParams,
  UseUserRegisterParams,
  UseUserUpdateUserParams,
  UseUserChangePasswordParams,
} from './useUser';

const LOGIN_MUTATION = `
mutation AuthenticateCustomerWithSocialLogin($input: CustomerInput!) {
  authenticateCustomerWithSocialLogin(input: $input) {
    token
  }
}
`;

const FAILED_AUTHENTICATION_RESULT = 'Authentication failed!';

/**
 * Allows loading and manipulating data of the current user.
 *
 * See the {@link UseUserInterface} for a list of methods and values available in this composable.
 */

const localErrorsMessages = (errors: Array<string>) => {
  const BE_ERRORS = {
    'Specify the "email" value.': 'Please enter your email address.',
    'Specify the "password" value.': 'Please enter your password.',
  };
  return errors.map((m) => (BE_ERRORS[m] ? BE_ERRORS[m] : m));
};

/* eslint-disable prefer-destructuring */
export function useUser(): UseUserInterface {
  const customerStore = useCustomerStore();
  // @ts-ignore
  const { app, $recaptcha, localePath } = useContext();

  const { setCart } = useCart();
  const { mutate } = useApi();
  const loading: Ref<boolean> = ref(false);
  const errorsFactory = (): UseUserErrors => ({
    updateUser: null,
    register: null,
    login: null,
    logout: null,
    changePassword: null,
    load: null,
  });
  const error: Ref = ref(errorsFactory());

  const setUser = (newUser: Customer) => {
    customerStore.user = newUser;
    Logger.debug('useUserFactory.setUser', newUser);
  };

  const resetErrorValue = () => {
    error.value = errorsFactory();
  };

  const updateCustomerEmail = async (credentials: { email: string; password: string }): Promise<void> => {
    const { errors } = await app.context.$vsf.$magento.api.updateCustomerEmail(credentials);

    if (errors) {
      throw errors.map((e) => e.message).join(',');
    }
  };

  // eslint-disable-next-line consistent-return
  const updateUser = async ({ user: providedUser, customQuery, customHeaders }: UseUserUpdateUserParams) => {
    Logger.debug('[Magento] Update user information', { providedUser, customQuery, customHeaders });
    resetErrorValue();

    try {
      loading.value = true;
      const { email: oldEmail } = customerStore.user;
      const { email, password, ...updateData } = providedUser;

      const userData = generateUserData(updateData);

      if (email && email !== oldEmail) {
        await updateCustomerEmail({
          email,
          password,
        });
      }

      const { data, errors } = await app.context.$vsf.$magento.api.updateCustomer(userData, customQuery, customHeaders);
      Logger.debug('[Result]:', { data });

      if (errors) {
        const allErrorMessages = errors.map((e) => e.message).join(',');
        Logger.error(allErrorMessages);
        error.value.updateUser = allErrorMessages;
      }

      customerStore.user = data?.updateCustomerV2?.customer || {};
      error.value.updateUser = null;
    } catch (err) {
      error.value.updateUser = err;
      Logger.error('useUser/updateUser', err);
    } finally {
      loading.value = false;
    }
  };

  const logout = async ({ customQuery = {}, customHeaders = {} }: UseUserLogoutParams = {}) => {
    Logger.debug('[Magento] useUserFactory.logout');
    resetErrorValue();

    try {
      const apiState = app.context.$vsf.$magento.config.state;

      await app.context.$vsf.$magento.api.revokeCustomerToken(customQuery, customHeaders);

      apiState.removeCustomerToken();
      apiState.removeCartId();
      setCart(null);
      customerStore.setIsLoggedIn(false);
      error.value.logout = null;
      customerStore.user = null;
    } catch (err) {
      error.value.logout = err;
      Logger.error('useUser/logout', err);
    }
  };

  const load = async ({ customQuery = {}, customHeaders = {} }: UseUserLoadParams = {}) => {
    Logger.debug('[Magento] useUser.load');
    resetErrorValue();

    try {
      loading.value = true;
      const apiState = app.context.$vsf.$magento.config.state;

      if (!apiState.getCustomerToken()) {
        return null;
      }
      try {
        const { data } = await app.context.$vsf.$magento.api.customer(customQuery, customHeaders);

        Logger.debug('[Result]:', { data });

        customerStore.user = data?.customer ?? {};
      } catch {
        // eslint-disable-next-line no-void
        // @ts-ignore
        await logout();
      }
      error.value.load = null;
    } catch (err) {
      error.value.load = err;
      Logger.error('useUser/load', err);
    } finally {
      loading.value = false;
    }

    return customerStore.user;
  };

  // eslint-disable-next-line @typescript-eslint/require-await,no-empty-pattern
  const login = async ({ user: providedUser, customQuery, customHeaders }: UseUserLoginParams): Promise<void> => {
    Logger.debug('[Magento] useUser.login', providedUser);
    resetErrorValue();

    try {
      loading.value = true;
      const apiState = app.context.$vsf.$magento.config.state;

      const { data, errors } = await app.$vsf.$magento.api.generateCustomerToken(
        {
          email: providedUser.email,
          password: providedUser.password,
          recaptchaToken: providedUser.recaptchaToken,
        },
        customQuery || {},
        customHeaders || {},
      );
      Logger.debug('[Result]:', { data });

      if (errors) {
        const joinedErrors = localErrorsMessages(errors.map((e) => e.message)).join(',');
        Logger.error(joinedErrors);
        // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
        throw new Error(joinedErrors);
      }

      customerStore.setIsLoggedIn(true);
      apiState.setCustomerToken(data.generateCustomerToken.token);

      // merge existing cart with customer cart
      // todo: move this logic to separate method
      const currentCartId = apiState.getCartId();
      const cart = await app.context.$vsf.$magento.api.customerCart();
      const newCartId = cart.data.customerCart.id;

      try {
        if (newCartId && currentCartId && currentCartId !== newCartId) {
          const { data: dataMergeCart } = await app.context.$vsf.$magento.api.mergeCarts({
            sourceCartId: currentCartId,
            destinationCartId: newCartId,
          });

          // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
          setCart(dataMergeCart.mergeCarts);

          apiState.setCartId(dataMergeCart.mergeCarts.id);
        } else {
          // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
          setCart(cart.data.customerCart);
        }
      } catch {
        // Workaround for Magento 2.4.4 mergeCarts mutation error related with Bundle products
        // It can be removed when Magento 2.4.5 will be release
        // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
        setCart(cart.data.customerCart);
      }
      error.value.login = null;
    } catch (err) {
      error.value.login = err;
      Logger.error('useUser/login', err);
    } finally {
      loading.value = false;
    }
  };

  const redirectURIbuilder = (platform: string) => {
    const ORIGIN = window.location.origin;
    const URL = localePath('/authentication');
    const BUILT_URI = `${ORIGIN}${URL}/${platform}`;
    return BUILT_URI;
  };

  const socialAuthenticationGOOGLE = (REDIRECT_URI: string) => {
    const GOOGLE_AUTHENTICATION_ID = process.env.GOOGLE_AUTHENTICATION_ID;
    const GOOGLE_AUTHENTICATION_REDIRECT_URI = REDIRECT_URI;
    const responseType = 'token';
    const scope = 'profile email';
    const GOOGLE_AUTHENTICATION_URI = `https://accounts.google.com/o/oauth2/auth?client_id=${GOOGLE_AUTHENTICATION_ID}&redirect_uri=${GOOGLE_AUTHENTICATION_REDIRECT_URI}&response_type=${responseType}&scope=${scope}`;

    window.location.href = GOOGLE_AUTHENTICATION_URI;
  };

  const socialAuthenticationFACEBOOK = (REDIRECT_URI: string) => {
    const FACEBOOK_AUTHENTICATION_ID = process.env.FACEBOOK_AUTHENTICATION_ID;
    const FACEBOOK_AUTHENTICATION_REDIRECT_URI = REDIRECT_URI;
    const responseType = 'token';
    const scope = 'email,public_profile';
    const FACEBOOK_AUTHENTICATION_URI = `https://www.facebook.com/v20.0/dialog/oauth?client_id=${FACEBOOK_AUTHENTICATION_ID}&redirect_uri=${FACEBOOK_AUTHENTICATION_REDIRECT_URI}&response_type=${responseType}&scope=${scope}`;

    window.location.href = FACEBOOK_AUTHENTICATION_URI;
  };

  const socialAuthenticationAMAZON = (REDIRECT_URI: string) => {
    const AMAZON_AUTHENTICATION_ID = process.env.AMAZON_AUTHENTICATION_ID;
    const AMAZON_AUTHENTICATION_REDIRECT_URI = REDIRECT_URI;
    const responseType = 'code';
    const scope = 'profile';
    const AMAZON_AUTHENTICATION_URI = `https://www.amazon.com/ap/oa?client_id=${AMAZON_AUTHENTICATION_ID}&redirect_uri=${AMAZON_AUTHENTICATION_REDIRECT_URI}&response_type=${responseType}&scope=${scope}`;

    window.location.href = AMAZON_AUTHENTICATION_URI;
  };

  const socialLoginGOOGLE = async (token: string) => {
    if (!token) {
      return;
    }
    try {
      const GOOGLE_0AUTH_URL = 'https://www.googleapis.com/oauth2/v1';
      const { data } = await axios.get(`${GOOGLE_0AUTH_URL}/userinfo?access_token=${token}`);

      const user = {
        firstname: data.given_name,
        lastname: data.family_name,
        email: data.email,
        socialId: data.id,
        socialLoginType: 'google',
      };

      const res: any = await mutate(LOGIN_MUTATION, { input: user });
      const successAuthenticationToken = !!res?.data?.authenticateCustomerWithSocialLogin?.token
        && res?.data?.authenticateCustomerWithSocialLogin?.token !== FAILED_AUTHENTICATION_RESULT;

      if (successAuthenticationToken) {
        const apiState = app.context.$vsf.$magento.config.state;
        customerStore.setIsLoggedIn(true);
        customerStore.setIsSocialsAuthentication(true);
        apiState.setCustomerToken(res?.data?.authenticateCustomerWithSocialLogin?.token);
      }
    } catch (err) {
      console.error('Social login error:', err);
    }
  };

  const socialLoginFACEBOOK = async (token: string) => {
    if (!token) {
      return;
    }
    try {
      const FACEBOOK_0AUTH_URL = 'https://graph.facebook.com';
      const { data } = await axios.get(`${FACEBOOK_0AUTH_URL}/me?access_token=${token}&fields=id,first_name,last_name,email`);

      const user = {
        firstname: data.first_name,
        lastname: data.last_name,
        email: data.email,
        socialId: data.id,
        socialLoginType: 'facebook',
      };

      const res: any = await mutate(LOGIN_MUTATION, { input: user });
      const successAuthenticationToken = !!res?.data?.authenticateCustomerWithSocialLogin?.token
        && res?.data?.authenticateCustomerWithSocialLogin?.token !== FAILED_AUTHENTICATION_RESULT;

      if (successAuthenticationToken) {
        const apiState = app.context.$vsf.$magento.config.state;
        customerStore.setIsLoggedIn(true);
        customerStore.setIsSocialsAuthentication(true);
        apiState.setCustomerToken(res?.data?.authenticateCustomerWithSocialLogin?.token);
      }
    } catch (err) {
      console.error('Social login error:', err);
    }
  };

  const socialLoginAMAZON = async (code: string) => {
    const REDIRECT_URI = redirectURIbuilder('amazon');
    if (!code) {
      return;
    }
    try {
      const AMAZON_TOKEN_URL = 'https://api.amazon.com/auth/o2/token';
      const tokenResponse = await axios.post(AMAZON_TOKEN_URL, {
        grant_type: 'authorization_code',
        code,
        redirect_uri: REDIRECT_URI,
        client_id: process.env.AMAZON_CLIENT_ID,
        client_secret: process.env.AMAZON_CLIENT_SECRET,
      });

      const token = tokenResponse.data.access_token;

      const AMAZON_0AUTH_URL = 'https://api.amazon.com';
      const { data } = await axios.get(`${AMAZON_0AUTH_URL}/user/profile?access_token=${token}`);

      const user = {
        firstname: data.name.split(' ')[0],
        lastname: data.name.split(' ')[1],
        email: data.email,
        socialId: data.user_id,
        socialLoginType: 'amazon',
      };

      const res: any = await mutate(LOGIN_MUTATION, { input: user });
      const successAuthenticationToken = !!res?.data?.authenticateCustomerWithSocialLogin?.token
        && res?.data?.authenticateCustomerWithSocialLogin?.token !== FAILED_AUTHENTICATION_RESULT;

      if (successAuthenticationToken) {
        const apiState = app.context.$vsf.$magento.config.state;
        customerStore.setIsLoggedIn(true);
        customerStore.setIsSocialsAuthentication(true);
        apiState.setCustomerToken(res?.data?.authenticateCustomerWithSocialLogin?.token);
      }
    } catch (err) {
      console.error('Social login error:', err);
    }
  };

  // eslint-disable-next-line consistent-return
  const register = async ({ user: providedUser, customQuery, customHeaders }: UseUserRegisterParams): Promise<void> => {
    Logger.debug('[Magento] useUser.register', providedUser);
    resetErrorValue();

    try {
      loading.value = true;

      const {
        email, password, recaptchaToken, ...baseData
      } = generateUserData(providedUser);

      const { data, errors } = await app.$vsf.$magento.api.createCustomer(
        {
          email,
          password,
          recaptchaToken,
          ...baseData,
        },
        customQuery || {},
        customHeaders || {},
      );

      Logger.debug('[Result]:', { data });

      if (errors) {
        const joinedErrors = localErrorsMessages(errors.map((e) => e.message)).join(',');
        Logger.error(joinedErrors);
        // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
        throw new Error(joinedErrors);
      }
      error.value.register = null;
      let loginRecaptchaToken = '';
      if ($recaptcha && recaptchaToken) {
        loginRecaptchaToken = await $recaptcha.getResponse();
      }
      const {
        // eslint-disable-next-line @typescript-eslint/naming-convention
        customer: { customer_create_account_confirm },
      } = app.context.$vsf.$magento.config;

      if (customer_create_account_confirm) {
        return await new Promise((resolve) => {
          resolve();
        });
      }
      await login({ user: { email, password, recaptchaToken: loginRecaptchaToken }, customQuery: {} });
    } catch (err) {
      error.value.register = err;
      Logger.error('useUser/register', err);
    } finally {
      loading.value = false;
    }
  };

  // eslint-disable-next-line consistent-return
  const changePassword = async (params: UseUserChangePasswordParams) => {
    Logger.debug('[Magento] useUser.changePassword', { currentPassword: mask(params.current), newPassword: mask(params.new) });
    resetErrorValue();

    try {
      loading.value = true;

      const { data, errors } = await app.context.$vsf.$magento.api.changeCustomerPassword(
        {
          currentUser: customerStore.user,
          currentPassword: params.current,
          newPassword: params.new,
        },
        params.customQuery,
        params?.customHeaders,
      );

      let joinedErrors = null;

      if (errors) {
        joinedErrors = errors.map((e) => e.message).join(',');
        Logger.error(joinedErrors);
      }

      Logger.debug('[Result] ', { data });

      if (data?.changeCustomerPassword) {
        customerStore.user = data?.changeCustomerPassword;
      }

      error.value.changePassword = joinedErrors;
    } catch (err) {
      error.value.changePassword = err;
      Logger.error('useUser/changePassword', err);
    } finally {
      loading.value = false;
    }
  };

  return {
    setUser,
    updateUser,
    register,
    login,
    logout,
    changePassword,
    load,
    socialAuthenticationGOOGLE,
    socialAuthenticationFACEBOOK,
    socialAuthenticationAMAZON,
    socialLoginGOOGLE,
    socialLoginFACEBOOK,
    socialLoginAMAZON,
    redirectURIbuilder,
    loading: readonly(loading),
    error: readonly(error),
    user: computed(() => customerStore.user),
    isAuthenticated: computed(() => customerStore.isLoggedIn),
    isSocialsAuthentication: computed(() => customerStore.isSocialsAuthentication),
  };
}

export default useUser;
export * from './useUser';
